<template>
  
</template>

<script>
export default {
    mounted(){
        console.log(this.$store)
    }
}
</script>

<style>

</style>